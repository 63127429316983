<template>
  <div class="homebackh">
    <div class="honors">
      <div class="honors-inner">
        <div class="honor-title">
          <!-- <i class="el-icon-arrow-right"></i> -->
          <strong> 企业荣誉&nbsp;</strong>
          <span class="shutiao">|</span>
          <strong>&nbsp;Honors</strong>
        </div>
        <Swiper
          v-if="gloryData.length > 0"
          :speed="2"
          :direction="'right'"
          :gloryData="gloryData"
        ></Swiper>
      </div>
    </div>
    <div class="agent-intro">
      <div class="agent-inner">
        <br />
        <div class="agent-title">
          <strong>涉外代理&nbsp;</strong>
          <span class="shutiao">|</span>
          <strong>&nbsp;Agent</strong>
        </div>
        <div class="agent-pic">
          <img class="agentpic" src="@/assets/pic3.jpg" alt="" />
        </div>
        <div class="agent-text">
          <br />
          <span class="shewai">涉外代理</span>
          <br /><br />
          <div class="agentintro" v-html="foreAgentData.content"></div>
          <span style="margin-left: 5%">...</span>
          <br /><br />
          <span class="more" @click="gotoForeAgent">>更多</span>
        </div>
      </div>
      <div class="intro-inner">
        <br />
        <div class="intro-title">
          <strong>公司介绍&nbsp;</strong>
          <span class="shutiao">|</span>
          <strong>&nbsp;Introduction</strong>
        </div>
        <div class="intro-pic">
          <img class="intropic" src="@/assets/comp.jpg" alt="" />
        </div>
        <div class="intro-text">
          <br />
          <span class="gongsi">公司概况</span>
          <br /><br />
          <div class="comp-intro" v-html="compData.content"></div>
          <span style="margin-left: 5%">...</span>
          <br /><br />
          <span class="more" @click="gotoCompMore">>更多</span>
        </div>
      </div>
    </div>
    <div class="culture-news">
      <div class="culture-inner">
        <br />
        <div class="cul-title">
          <strong>公司文化&nbsp;</strong>
          <span class="shutiao">|</span>
          <strong>&nbsp;Culture</strong>
        </div>
        <div class="cul-inner">
          <div class="cul-pic">
            <img class="culpic" src="@/assets/pic2.jpg" alt="" />
          </div>

          <div class="kouhao">
            <span class="cul-text">诚信</span>
            <br />
            <span class="cul-text">专业</span>
            <br />
            <span class="cul-text">责任</span>
            <br />
            <span class="cul-text">效率</span>
          </div>
        </div>
      </div>
      <div class="news-inner">
        <br />
        <div class="news-title1">
          <strong>新闻动态&nbsp;</strong>
          <span class="shutiao">|</span>
          <strong>&nbsp;News</strong>
        </div>
        <div class="news-list">
          <div v-for="item in newsData" :key="item.title" class="newslist">
            <span @click="gotoNews(item.newsid)" :title="item.title"
              >（{{ item.wtime }}）{{ item.title }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="services-scope">
      <div class="scope-inner">
        <div class="scope">
          <br />
          <div class="scope-title">
            <strong>服务领域&nbsp;</strong>
            <span class="shutiao">|</span>
            <strong>&nbsp;Services</strong>
          </div>
          <div class="listall">
            <div v-for="item in scopeList" :key="item.title" class="list">
              <span @click="gotoService(item.serviceid)" :title="item.title">{{
                item.title
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="services-inner">
        <div class="services">
          <br />
          <div class="scope-title1">
            <strong>服务领域&nbsp;</strong>
            <span class="shutiao1">|</span>
            <strong>&nbsp;Services</strong>
          </div>

          <div class="list1all">
            <span class="wfw" v-show="LengthVisible">无服务</span>
            <div v-for="item in serviceList" :key="item.title" class="list1">
              <span
                @click="gotoServiceDetail(item.subid)"
                :title="item.title"
                >{{ item.title }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "../component/BaseSwiper";
import { GlobalMixin } from "@/mixins/globalMixin";
export default {
  components: { Swiper },
  mixins: [GlobalMixin],
  data() {
    return {
      scopeList: [], //服务领域
      serviceList: [], //服务
      compData: [], //公司概述
      foreAgentData: [], //涉外代理
      gloryData: [], //公司荣誉
      newsData: [], //新闻动态
      currentPage: 1,
      pageSize: 10,
      id: 6,
      // serviceVisible: false,
      // TipVisible: true,
      LengthVisible: false,
      imgList: [],
    };
  },
  created() {
    this.getGloryData();
  },
  mounted() {
    this.getCompData();
    this.getforeAgentData();
    this.getScopeData();
    this.getGloryData();
    this.getServiceData();
    this.getNewsData();
  },
  activated() {
    this.getCompData();
    this.getforeAgentData();
    this.getScopeData();
    this.getGloryData();
    this.getNewsData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },

  methods: {
    //涉外代理更多
    gotoForeAgent() {
      this.$router.push("/layout/foreAgent");
    },
    //公司概述更多
    gotoCompMore() {
      this.$router.push("/layout/compProfile");
    },
    //获取公司概述
    async getCompData() {
      await this.$axios
        .get("/profiles/GetProfile", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.compData = { ...response.data };
        });
    },
    //获取涉外代理
    async getforeAgentData() {
      await this.$axios
        .get("/agents/GetAgentAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.foreAgentData = response.data.data[0];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //获取服务领域
    async getScopeData() {
      await this.$axios
        .get("/services/GetServiceAll", {
          params: {
            pagesize: 20,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.scopeList = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //获取公司荣誉
    async getGloryData() {
      await this.$axios
        .get("/honors/GetHonorAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.gloryData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //获取新闻动态
    async getNewsData() {
      await this.$axios
        .get("/news/GetNewsHome", {})
        .then((response) => {
          if (response.data.status === "OK") {
            this.newsData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //显示服务
    gotoService(id) {
      // this.serviceVisible = true;
      // (this.TipVisible = false),
      this.id = id;
      this.getServiceData();
    },
    //跳转服务详情
    gotoServiceDetail(id) {
      this.$router.push({
        name: "serviceDetail",
        params: { str: id },
      });
    },
    //跳转新闻详情
    gotoNews(id) {
      this.$router.push({
        name: "newsDetail",
        params: { str: id },
      });
    },
    //获取服务
    async getServiceData() {
      await this.$axios
        .get("/subservices/GetSubAll", {
          params: {
            serviceid: this.id,
            pagesize: 20,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.serviceList = response.data.data;
            this.total = response.data.total;
            // this.TipVisible = false;
            if (this.total == 0) {
              this.LengthVisible = true;
            } else {
              this.LengthVisible = false;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
/* PC端 */
@media screen and (min-width: 800px) {
  .gongsi {
    font-size: 25px;
    margin-left: 5%;
  }
  .shutiao {
    font-size: 40px;
  }
  .homebackh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(173, 172, 172, 0.8)
      ),
      url("/assets/back.png") no-repeat;
    background-size: 100% 100%;
  }

  .honors-inner {
    width: 95%;
    height: 380px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3%;
  }
  .agent-intro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    margin-left: 5%;
    margin-top: 1.3%;
  }
  .agent-inner {
    background-color: rgb(255, 255, 255);
    width: 46%;
    height: 95%;
    margin-left: -5%;
  }
  .intro-inner {
    background-color: rgb(255, 255, 255);
    width: 47%;
    height: 95%;
    margin-left: 2%;
  }
  .agentpic {
    width: 350px;
    height: 350px;
    margin-top: 2%;
    margin-left: 35%;
    border-radius: 3px;
    margin-bottom: 5%;
  }
  .agent-text {
    z-index: 1;
    height: 260px;
    position: absolute;
    margin-top: -20%;
    margin-left: 5%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(211, 210, 210);
    display: inline-block;
    width: 280px;
    word-wrap: break-word;
    white-space: normal;
    border-radius: 5px;
  }
  .shewai {
    font-size: 25px;
    margin-left: 5%;
  }
  .more {
    color: rgb(0, 115, 255);
    cursor: pointer;
    margin-left: 5%;
  }
  .agentintro {
    width: 90%;
    height: 125px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5%;
  }
  .intropic {
    width: 350px;
    height: 350px;
    margin-bottom: 5%;
    margin-left: 35%;
    margin-top: 2%;
    border-radius: 3px;
  }

  .intro-text {
    z-index: 1;
    height: 260px;
    position: absolute;
    margin-top: -20%;
    margin-left: 5%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(211, 210, 210);
    display: inline-block;
    width: 280px;
    word-wrap: break-word;
    white-space: normal;
    border-radius: 5px;
  }
  .comp-intro {
    width: 90%;
    height: 125px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5%;
  }
  .culture-news {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    margin-left: 5%;
    margin-top: 2%;
  }
  .culture-inner {
    background-color: rgb(255, 255, 255);
    width: 46%;
    /* height: 95%; */
    height: 504px;
    margin-left: -5%;
  }
  .kouhao {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -5%;
    font-size: 30px;
    margin-top: -6%;
  }
  .culpic {
    width: 380px;
    height: 300px;
    margin-top: 15%;
    margin-left: 2%;
    border-radius: 3px;
  }
  .cul-pic {
    width: 450px;
    height: 300px;
    margin-top: -6%;
    margin-left: -4%;
  }
  .news-inner {
    background-color: rgb(255, 255, 255);
    width: 47%;
    height: 504px;
    margin-left: 2%;
  }
  .news-title1 {
    font-size: 25px;
    margin-top: 1%;
    margin-left: 5%;
  }
  .news-list {
    margin-top: 1%;
  }
  .cul-title {
    font-size: 25px;
    margin-top: 1%;
    margin-left: 4%;
  }
  .honor-title {
    font-size: 25px;
    margin-top: 1%;
    margin-left: 2%;
  }
  .agent-title {
    font-size: 25px;
    margin-top: 1%;
    margin-left: 4%;
  }
  .intro-title {
    font-size: 25px;
    margin-top: 1%;
    margin-left: 4%;
  }
  .honors {
    height: 15%;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 5%;
  }
  .scope-title {
    font-size: 25px;
    margin-top: 1%;
    margin-left: 5%;
  }
  .scope-title1 {
    visibility: hidden;
    font-size: 25px;
    margin-top: 1%;
    margin-left: 5%;
  }
  .shutiao1 {
    font-size: 40px;
  }

  .listall {
    /* display: flex;
    flex-wrap: wrap; */
    margin-left: 10%;
    width: 100%;
    height: auto;
    /* justify-content: center; */
    /* margin-bottom: -5%; */
  }
  .list {
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(110, 110, 110);
    margin-top: 25px;
    font-size: 20px;
    /* text-align: center; */
    cursor: pointer;
    width: 30%;
    margin-left: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-content: center;
  }
  .list:hover {
    border-bottom: 1px solid rgb(61, 144, 244);
  }
  .list1all {
    /* display: flex;
    flex-wrap: wrap; */
    margin-left: 5%;
    height: auto;
    /* margin-top: 20px; */

    /* margin-bottom: 5%; */
  }
  .list1 {
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(110, 110, 110);
    margin-top: 25px;
    font-size: 20px;
    cursor: pointer;
    /* font-weight: 500; */
    width: 520px;
    margin-left: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-content: center;
  }
  .list1:hover {
    border-bottom: 1px solid rgb(61, 144, 244);
  }

  .newslist {
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(110, 110, 110);
    margin-top: 5.1%;
    font-size: 17px;
    cursor: pointer;
    width: 86%;
    margin-left: 7%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .services-scope {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 900px;
    /* height: auto; */
    margin-left: -2%;
    margin-top: 2%;
    /* height: 400px; */
    margin-bottom: 3%;
  }

  .scope-inner {
    background-color: rgb(255, 255, 255);
    width: 45.8%;
    height: 100%;
    margin-left: 2.1%;
    /* border-right: 2px dashed rgba(152, 152, 152, 0.8); */
    padding-right: 4px;
  }
  .wfw {
    display: inline-block;
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(110, 110, 110);
    margin-top: 24px;
    font-size: 20px;
    width: 200px;
    margin-left: 30px;
  }
  .cul-inner {
    width: 100%;
    height: 400px;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;
    margin-left: 2%;
  }

  .cul-text {
    /* border: 2px dashed rgb(67, 65, 65); */
    border-radius: 2px;
    padding: 4px 8px 4px 8px;
  }

  .services-inner {
    background-color: rgb(255, 255, 255);
    width: 47%;
    height: 100%;
    /* height: auto; */
    margin-left: 2%;
  }
  .service-title {
    font-size: 25px;
    margin-top: -3%;
    margin-left: 2%;
  }
  /* .services {
    margin-left: 5%;
  margin-top: 17%;
  } */
}
/* 移动端 */
@media screen and (max-width: 700px) {
  .gongsi {
    font-size: 25px;
    margin-left: 5%;
  }
  .shutiao {
    font-size: 40px;
  }
  .homebackh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(173, 172, 172, 0.8)
      ),
      url("/assets/back.png") no-repeat;
    background-size: 100% 100%;
  }

  .honors-inner {
    width: 95%;
    height: 380px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3%;
  }
  .agent-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 20%; */
    margin-left: 5%;
  }
  .agent-inner {
    background-color: rgb(255, 255, 255);
    width: 95%;
    height: 390px;
    margin-left: -5%;
    margin-top: -0.5%;
  }
  .intro-inner {
    background-color: rgb(255, 255, 255);
    width: 95%;
    height: 390px;
    margin-left: -5%;
    margin-top: 1.3%;
  }
  .agentpic {
    width: 220px;
    height: 240px;
    margin-top: 2%;
    margin-left: 50%;
    border-radius: 3px;
    margin-bottom: 5%;
  }
  .agent-text {
    z-index: 1;
    height: 250px;
    position: absolute;
    margin-top: -45%;
    margin-left: 5%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(211, 210, 210);
    display: inline-block;
    width: 230px;
    word-wrap: break-word;
    white-space: normal;
    border-radius: 5px;
  }
  .shewai {
    font-size: 22px;
    margin-left: 5%;
  }
  .more {
    color: rgb(0, 115, 255);
    cursor: pointer;
    margin-left: 5%;
    margin-top: -7%;
    display: block;
  }
  .agentintro {
    width: 90%;
    height: 125px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5%;
    margin-top: -5%;
  }
  .intropic {
    width: 220px;
    height: 240px;
    margin-top: 2%;
    margin-left: 50%;
    border-radius: 3px;
    margin-bottom: 5%;
  }

  .intro-text {
    z-index: 1;
    height: 250px;
    position: absolute;
    margin-top: -45%;
    margin-left: 5%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(211, 210, 210);
    display: inline-block;
    width: 230px;
    word-wrap: break-word;
    white-space: normal;
    border-radius: 5px;
  }
  .comp-intro {
    width: 90%;
    height: 125px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5%;
    margin-top: -5%;
  }
  .culture-news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 20%; */
    /* margin-left: 5%; */
    /* margin-top: 2%; */
  }
  .culture-inner {
    background-color: rgb(255, 255, 255);
    width: 95%;
    height: 390px;
    margin-top: 1.3%;
  }
  .kouhao {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    font-size: 25px;
    margin-top: -32%;
  }
  .culpic {
    width: 280px;
    height: 250px;
    margin-top: 15%;
    margin-left: 2%;
    border-radius: 3px;
  }
  .cul-pic {
    width: 320px;
    height: 250px;
    margin-top: -10%;
    margin-left: -8%;
  }
  .news-inner {
    background-color: rgb(255, 255, 255);
    width: 95%;
    height: 390px;
    /* margin-left: 2%; */
    margin-top: 1.3%;
  }
  .news-title1 {
    font-size: 25px;
    /* margin-top: 1%; */
    margin-left: 5%;
  }
  .news-list {
    margin-top: -3%;
  }
  .cul-title {
    font-size: 25px;
    /* margin-top: 1%; */
    margin-left: 4%;
  }
  .honor-title {
    font-size: 25px;
    /* margin-top: 1%; */
    margin-left: 2%;
  }
  .agent-title {
    font-size: 25px;
    /* margin-top: 1%; */
    margin-left: 4%;
  }
  .intro-title {
    font-size: 25px;
    /* margin-top: 1%; */
    margin-left: 4%;
  }
  .honors {
    height: 15%;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-top: 5%;
  }
  .scope-title {
    font-size: 25px;
    margin-top: 1%;
    margin-left: 5%;
    width: 110%;
  }
  .listall {
    /* display: flex; */
    /* flex-wrap: wrap; */
    margin-left: 10.5%;
    width: 100%;
    /* margin-bottom: -5%; */
  }
  .list {
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(110, 110, 110);
    margin-top: 5%;
    font-size: 18px;
    /* text-align: center; */
    cursor: pointer;
    width: 80%;
    margin-left: 1%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .list:hover {
    border-bottom: 1px solid rgb(61, 144, 244);
  }
  .list1all {
    /* display: flex; */
    /* flex-wrap: wrap; */
    margin-top: -17%;
    /* margin-bottom: 5%; */
  }
  .list1 {
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(110, 110, 110);
    margin-top: 5%;
    font-size: 18px;
    cursor: pointer;
    /* font-weight: 500; */
    width: 95%;
    margin-left: 1%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .list1:hover {
    border-bottom: 1px solid rgb(61, 144, 244);
  }

  .newslist {
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(110, 110, 110);
    margin-top: 5.6%;
    font-size: 17px;
    cursor: pointer;
    width: 86%;
    margin-left: 7%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .services-scope {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 900px;
    margin-left: -2%;
    margin-top: 2%;
    /* height: 400px; */
    margin-bottom: 3%;
  }

  .scope-inner {
    background-color: rgb(255, 255, 255);
    width: 46.8%;
    height: 100%;
    margin-left: 2%;
    border-right: 2px dashed rgba(152, 152, 152, 0.8);
    padding-right: 4px;
  }
  .wfw {
    font-size: 20px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(110, 110, 110);
    display: block;
    margin-top: -13%;
  }
  .cul-inner {
    width: 100%;
    height: 400px;

    border-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;
    margin-left: 2%;
  }

  .cul-text {
    /* border: 2px dashed rgb(67, 65, 65); */
    border-radius: 2px;
    padding: 4px 8px 4px 8px;
  }

  /* .scope {
  height: 100%;
  margin-bottom: 10px;
  width: auto;
  margin-left: 10%;
  margin-top: 10%;
} */

  .services-inner {
    background-color: rgb(255, 255, 255);
    width: 47.8%;
    height: 100%;
    /* margin-left: 2%; */
  }

  .service-title {
    font-size: 25px;
    margin-top: -3%;
    margin-left: 2%;
  }
  .services {
    margin-left: 5%;
    margin-top: 17%;
  }
}

/* 公司荣誉 */
</style>