<template>
  <div class="container">
    <div class="left-side">
      <img :src="imagefile.url" alt="Image from PDF" style="height: auto; width: 80%;" />
    </div>
    <div class="right-side">
      <div class="title">
        <span>{{ conversionData.cname }}</span>
      </div>
      <div class="content">
        <div class="contentDetail">申请号:
          <span>{{conversionData.patentnum}}</span>
        </div>
        <div class="contentDetail">申请日期:
          <span>{{conversionData.appdate}}</span>
        </div>
        <div class="contentDetail">申请(专利权)人:
          <span>{{conversionData.applicant}}</span>
        </div>
        <div class="contentDetail">简要介绍:<br/>
          <span>{{conversionData.content}}</span>
        </div>
      </div>
      <div class="attachments">
        <div class="attach-vedio">
          <video-player class="video-player vjs-custom-skin"
                        style="height: auto; width: 100%;"
                        ref="videoPlayer"
                        :options="playerOptions">
          </video-player>
        </div>
        <div class="attachment-list">
          <div v-for="(file, index) in conversionData.attachfiles" :key="index" class="attachDetail">
            <img :src="require('@/assets/pdf.png')" alt="Image from PDF" style="max-width: 40px; height: auto;"/>
            <div style="margin-left: 8px;font-size: 15px;">
              <span>{{file.name}}</span>
              <div class="downOrPreview">
                <a :href="file.url" @click.prevent="previewFile(file)">
                  <i class="el-icon-view"></i>
                  预览
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player/src'
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';


import axios from "axios";

export default {
  components:{
    videoPlayer,
  },
  data() {
    return {
      pdfPreviewUrl: "",
      viewVisible:false,
      playerOptions: {
        // component options
        start: 0,
        playsinline: false,
        // videojs options
        muted: true,
        language: 'zh-CN',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "",
          src: ""
        }],
        poster: '',
        fluid: true,
      },
      imagefile:{
        name:"",
        url:"",
      },
      conversionData: {
        cname:"",
        content:"",
        patentnum:"",
        appdate:"",
        applicant:"",
        attachfiles:[
          {
            name:""
          },
        ]
      },
    };
  },
  created() {
  },
  mounted() {
    this.getConversionData();
  },
  activated() {
    this.getConversionData();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    previewFile(file) {
      this.pdfPreviewUrl = axios.defaults.baseURL + `files/preview/${file.fname}`;
      window.open('/pdfjs/web/viewer.html?file='+this.pdfPreviewUrl)
    },
    async getConversionData() {
      await this.$axios
          .get("/conversion/GetConversion", {
            params: {
              cid: this.$route.params.str,
            },
          })
          .then((response) => {
            this.conversionData = { ...response.data };
            this.conversionData.attachfiles = []
            response.data.files.forEach((file) => {
              file.name = file.fname.split('_')[1]; // 添加 name 属性
              if(file.fsite === "1"){
                this.imagefile = file
              }else if(file.fsite === "2"){
                this.playerOptions.sources[0].src = file.url
              }else{
                this.conversionData.attachfiles.push(file)
              }
            })
          });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  background-color: #ffffff;
  flex-direction: row;
  padding-top: 50px;
  height: auto;
}
.left-side {
  width: 40%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.right-side {
  width: 50%;
  height: auto;
}
.title {
  padding-left: 10px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  padding-bottom: 15px;
  border-bottom: 1.5px solid rgb(110, 110, 110);
  width: 95%;
}
.content {
  padding-top: 15px;
  padding-left: 10px;
  font-size:20px;
  border-bottom: 1.5px solid rgb(110, 110, 110);
  width: 95%;
  height: auto;
}
.contentDetail{
  padding-bottom: 2px;
  line-height: 1.5;
}
.attachments {
  width: 95%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
}
.attachment-list {
  /*width: 40%;*/
  margin-left: 50px;
  /*margin-right: 50px;*/
  /*text-align: right;*/
}
.attachDetail {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.downOrPreview{
  /*display: flex;*/
  /*justify-content: center; !* 水平居中 *!*/
  /*align-items: center; !* 垂直居中 *!*/
  /*text-align: center; !* 可选：文本居中 *!*/
}
.downOrPreview a{
  text-decoration: none;
  color: #1296db;
}
.attach-vedio {
  width: 90%;
  height: auto;
  display: block;
}
::v-deep .video-js .vjs-tech {
  object-fit: fill;
}
::v-deep .vjs-poster {
  background-size: cover;
}
</style>